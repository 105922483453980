import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { RequestService } from '@services/request.service';
import { IRequestOptions } from '@common/interfaces';

@Injectable()
export class MessagesService {

  constructor(
    private request: RequestService,
  ) { }

  public getBlockedSms(params: any = {}): Observable<any> {
    params.limit = params.limit || 100;
    return this.request.send('get', '/api/messages/sms/blacklisted', params);
  }

  public getAllBlockedSmsForExport(params: IRequestOptions = {}): Observable<any> {
    const options = {
      ...params,
      reportProgress: true,
      responseType: 'text',
    };
    return this.request.send('get', '/api/messages/sms/blacklisted', options) as Observable<any>;
  }

  public getAllReceivedSms(params: any = {}): Observable<any> {
    params.limit = params.limit || 100;
    return this.request.send('get', '/api/messages/sms/received', params);
  }

  public getAllReceivedSmsForExport(params: IRequestOptions = {}): Observable<any> {
    const options = {
      ...params,
      reportProgress: true,
      responseType: 'text',
    };
    return this.request.send('get', '/api/messages/sms/received', options) as Observable<any>;
  }

  public getAllSmsTrackers(params: any = {}): Observable<any> {
    params.limit = params.limit || 100;
    return this.request.send('get', '/api/messages/sms/trackers', params);
  }

  public getAllSmsTrackersForExport(params: IRequestOptions = {}): Observable<any> {
    const options = {
      ...params,
      reportProgress: true,
      responseType: 'text',
    };
    return this.request.send('get', '/api/messages/sms/trackers', options) as Observable<any>;
  }

  public getAllEmailTrackers(params: any = {}): Observable<any> {
    params.limit = params.limit || 100;
    return this.request.send('get', '/api/messages/email/trackers', params);
  }

  public getAllEmailTrackersForExport(params: IRequestOptions = {}): Observable<any> {
    const options = {
      ...params,
      reportProgress: true,
      responseType: 'text',
    };
    return this.request.send('get', '/api/messages/email/trackers', options) as Observable<any>;
  }

  public getAllMessageTrackers(params: any = {}): Observable<any> {
    params.limit = params.limit || 100;
    return this.request.send('get', '/api/messages/message_trackers', params);
  }

  public getAllMessageTrackersForExport(params: IRequestOptions = {}): Observable<any> {
    const options = {
      ...params,
      reportProgress: true,
      responseType: 'text',
    };
    return this.request.send('get', '/api/messages/message_trackers', options) as Observable<any>;
  }
}
