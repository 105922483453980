import * as _ from 'lodash';
import { parseDate } from '@commonValidators/common-validators.service';
import { addressAsString } from '../utils/address.utils';
import { resolveFrequencyValues } from '@common/utils/quote.utils';
import { num2ViewString } from '@common/utils/number.utils';

/*
  Used for exporting reports from raw data
*/

export const ExportMapping: any = {

  // base mapping for all requests
  'base': [
    ['createdAt',              'createdAt'],
    ['updatedAt',              'updatedAt'],
  ],

  'brokers': [
    ['accountId',              'accountId'],
    ['status',                 'status'],
    ['firstName',              'firstName'],
    ['lastName',               'lastName'],
    ['email',                  'email'],
    ['companyName',            'companyName'],
    ['cellPhone',              'cellPhone'],
    ['email',                  'email'],

    ['street',                 'address.address1'],
    ['city',                   'address.city'],
    ['state',                  'address.state'],
    ['zipcode',                'address.zipcode'],

    ['deliveryMethod',         'deliveryMethod'],
    ['deliveryCellPhones',     'deliveryCellPhones', arrayToString],
    ['deliveryEmails',         'deliveryEmails', arrayToString],

    ['autoReplenish',          'autoReplenish'],
    ['replenishAmount',        'replenishAmount'],
    ['replenishLevel',         'replenishLevel'],
  ],

  'quotes': [
    ['accountId',              'accountId'],
    ['firstName',              'details.consumer.firstName'],
    ['lastName',               'details.consumer.lastName'],
    ['email',                  'details.consumer.email'],
    ['cellPhone',              'details.consumer.cellPhone'],
    ['utm_source',             'details.consumer.utm.utm_source'],
    ['utm_campaign',           'details.consumer.utm.utm_campaign'],
    ['utm_medium',             'details.consumer.utm.utm_medium'],
    ['utm_term',               'details.consumer.utm.utm_term'],
    ['address',                'details.consumer.currentAddress', addressAsString],
    ['insured',                'details.vertical.insured'],
    ['state',                  'state'],
    ['vertical',               'vertical'],
    ['gender',                 'details.consumer.gender'],
    ['acceptedOn',             'acceptedOn'],
    ['scheduling',             'inquiries.scheduling'],
    ['scheduled',              'inquiries.scheduled'],
    ['call',                   'inquiries.call'],
    ['contact type',           'contactType'],
    ['agent status',           'agentStatus'],
    ['first status agent',     'agentStatuses', firstAgentId],
    ['savings amount',         'savings.amount'],
    ['savings percentage',     'savings.percentage'],
    ['quote type',             'savings.type', quoteType],
    ['quote status',           'status'],
    ['quote cycle',            'quoteCycle', quoteCycle],
    ['num RT quotes',          'numRtQuotes'],
    ['num Manual quotes',      'numManualQuotes'],
    ['num MA quotes',          'mediaAlphaQuotes', numMAOffers],
    ['partners quoted',        'partners', getBrokersQuoted],
    ['carriers quoted',        'rtQuotes', numCarriersQuoted],
    ['current premium',        'details', currentPremium],
    ['current premium term',   'details', currentPremiumFrequency],
    ['lowest quote premium',   'savings.lowestPremium'],
    ['lowest quote term',      'savings.lowestPremiumTerm'],
    ['lowest quote monthly premium', 'savings.lowestMonthlyPremium'],
    ['lowest quote 6 month premium', 'savings.lowestSixMonthPremium'],
    ['lowest quote yearly premium', 'savings.lowestYearlyPremium'],
    ['lowest quote carrier', 'savings.lowestQuotedCarrier'],
    ['lowest quote carrier ID', 'savings.lowestQuotedCarrierId'],
    ['FP Quote % Difference', 'fp-to-quote-diff'],
    ['Accidents', 'accidents', removeUnderscoresFromStr],
    ['Violations', 'violations', removeUnderscoresFromStr],
  ],

  'consumers': [
    ['accountId',              'accountId'],
    ['firstName',              'firstName'],
    ['lastName',               'lastName'],
    ['email',                  'email'],
    ['gender',                 'gender'],
    ['maritalStatus',          'maritalStatus'],
    ['dateOfBirth',            'dateOfBirth', getDate],
    ['cellPhone',              'cellPhone'],
    ['preferences',            'preferences', arrayToString],

    ['addressStatus',          'addressStatus'],
    ['street',                 'currentAddress.street'],
    ['unit',                   'currentAddress.unit'],
    ['city',                   'currentAddress.city'],
    ['state',                  'currentAddress.state'],
    ['zipcode',                'currentAddress.zipcode'],
    ['country',                'currentAddress.country'],

    ['deliveryMethod',         'deliveryMethod'],
    ['deliveryCellPhones',     'deliveryCellPhones', arrayToString],
    ['deliveryEmails',         'deliveryEmails', arrayToString],

    ['educationLevel',         'educationLevel'],
    ['providedCreditRating',   'creditInfo.providedCreditRating'],
    ['employmentStatus',       'employmentInfo.status'],
    // ['directDeposit',          'employmentInfo.directDeposit'],
    // ['payFrequency',           'employmentInfo.payFrequency'],
    ['militaryStatus',         'militaryStatus'],

    ['accidentsIn5Years',      'drivingInfo.accidentsIn5Years'],
    ['ageFirstLicensed',       'drivingInfo.ageFirstLicensed'],
    ['driversLicenseState',    'drivingInfo.driversLicenseState'],
    ['licenseStatus',          'drivingInfo.licenseStatus'],
    ['isCustomer',             'isCustomer'],
    ['customerOn',             'customerOn'],
    ['fullstoryUrl',           'ids.fullStorySessionUrl'],
    ['inspectletUrl',           'ids.inspectletUrl'],
    ['smartlookUrl',           'ids.smartlookUrl'],
    ['utm_source',             'utm.utm_source'],
    ['utm_campaign',           'utm.utm_campaign'],
    ['utm_medium',             'utm.utm_medium'],
    ['utm_term',               'utm.utm_term'],
  ],

  'broker_insurance': [
    ['accountId',              'accountId'],
    ['status',                 'status'],
    ['primaryCarrier',         'primaryCarrier'],
    ['secondaryCarriers',      'secondaryCarriers', arrayToString],
    ['states',                 'states', arrayToString],
    ['quoteOnWeekends',        'quoteOnWeekends'],
    ['maxQuotesPerDay',        'maxQuotesPerDay'],
    ['noQuotesLimit',          'noQuotesLimit'],
    ['licenseNumbers',         'licenseNumbers', objectArrayToString],
  ],

  'consumer_insurance': [
    ['accountId',              'accountId'],
    ['completedOn',            'completedOn'],

    ['firstName',              'consumer.firstName'],
    ['lastName',               'consumer.lastName'],
    ['email',                  'consumer.email'],
    ['gender',                 'consumer.gender'],
    ['maritalStatus',          'consumer.maritalStatus'],
    ['dateOfBirth',            'consumer.dateOfBirth', getDate],
    ['cellPhone',              'consumer.cellPhone'],
    ['addressStatus',          'consumer.addressStatus'],
    ['street',                 'consumer.currentAddress.address1'],
    ['unit',                   'consumer.currentAddress.unit'],
    ['city',                   'consumer.currentAddress.city'],
    ['state',                  'consumer.currentAddress.state'],
    ['zipcode',                'consumer.currentAddress.zipcode'],
    ['country',                'consumer.currentAddress.country'],
    ['educationLevel',         'consumer.educationLevel'],
    ['providedCreditRating',   'consumer.creditInfo.providedCreditRating'],
    ['employmentStatus',       'consumer.employmentInfo.status'],
    ['militaryStatus',         'consumer.militaryStatus'],
    ['utm_source',             'consumer.utm.utm_source'],
    ['utm_medium',             'consumer.utm.utm_medium'],
    ['utm_campaign',           'consumer.utm.utm_campaign'],

    ['accidentsIn5Years',      'consumer.drivingInfo.accidentsIn5Years'],
    ['ageFirstLicensed',       'consumer.drivingInfo.ageFirstLicensed'],
    ['driversLicenseState',    'consumer.drivingInfo.driversLicenseState'],
    ['licenseStatus',          'consumer.drivingInfo.licenseStatus'],

    ['carrier',                'carrier'],
    ['collision',              'collision'],
    ['comprehensive',          'comprehensive'],
    ['drivers',                'drivers', arrayToString],
    ['expiration',             'expiration', getDate],
    ['premium',                'premium'],
    ['frequency',              'frequency'],
    ['insured',                'insured'],
    ['insuredFor',             'insuredFor'],
    ['vehicles',               'vehicles', arrayToString],
    ['lossOfUseEnabled',       'lossOfUse.enabled'],
    ['propertyDamageLiability', 'propertyDamageLiability'],
    ['bodilyInjuryLiabilityPerPerson',     'bodilyInjuryLiability.perPerson'],
    ['bodilyInjuryLiabilityPerAccident',   'bodilyInjuryLiability.perAccident'],
    ['roadsideAssistanceEnabled',          'roadsideAssistance.enabled'],
    ['personalInjuryProtectionOption',     'personalInjuryProtection.option'],
    ['personalInjuryProtectionDeductible', 'personalInjuryProtection.deductible'],
    ['unInsuredMotoristStacked',           'unInsuredMotorist.stacked'],
    ['unInsuredMotoristPerPerson',         'unInsuredMotorist.perPerson'],
    ['unInsuredMotoristPerAccident',       'unInsuredMotorist.perAccident'],
  ],

  'consumerHomeInsurances': [
    ['accountId',              'accountId'],

    ['firstName',              'consumer.firstName'],
    ['lastName',               'consumer.lastName'],
    ['email',                  'consumer.email'],
    ['gender',                 'consumer.gender'],
    ['maritalStatus',          'consumer.maritalStatus'],
    ['dateOfBirth',            'consumer.dateOfBirth', getDate],
    ['cellPhone',              'consumer.cellPhone'],
    ['addressStatus',          'consumer.addressStatus'],
    ['street',                 'consumer.currentAddress.address1'],
    ['unit',                   'consumer.currentAddress.unit'],
    ['city',                   'consumer.currentAddress.city'],
    ['state',                  'consumer.currentAddress.state'],
    ['zipcode',                'consumer.currentAddress.zipcode'],
    ['country',                'consumer.currentAddress.country'],
    ['educationLevel',         'consumer.educationLevel'],
    ['providedCreditRating',   'consumer.creditInfo.providedCreditRating'],
    ['employmentStatus',       'consumer.employmentInfo.status'],
    ['militaryStatus',         'consumer.militaryStatus'],

    ['property',               'property'],
    ['insured',                'insured'],
    ['insurance',              'insurance', objectToString],
    ['newInsurance',           'newInsurance', objectToString],
    ['claims',                 'claims', arrayToString],
    ['applicants',             'applicants', arrayToString],
    ['yearsAtResidence',       'yearsAtResidence'],
    ['mailingAddress',         'mailingAddress', objectToString],
    ['previousAddress',        'previousAddress', objectToString],
    ['reshopStartDate',        'reshopStartDate', getDate],
    ['requoteEnabled',         'requoteEnabled'],
    ['quoteCycle',             'quoteCycle'],
    ['stage',                  'stage'],
  ],

  'consumerLifeInsurances': [
    ['accountId',              'accountId'],

    ['firstName',              'consumer.firstName'],
    ['lastName',               'consumer.lastName'],
    ['email',                  'consumer.email'],
    ['cellPhone',              'consumer.cellPhone'],
    ['street',                 'consumer.currentAddress.street'],
    ['unit',                   'consumer.currentAddress.unit'],
    ['city',                   'consumer.currentAddress.city'],
    ['state',                  'consumer.currentAddress.state'],
    ['zipcode',                'consumer.currentAddress.zipcode'],
    ['country',                'consumer.currentAddress.country'],
    ['stage',                  'stage'],
    ['gender',                 'consumer.gender'],
    ['dateOfBirth',            'consumer.dateOfBirth', getDate],
    ['annualHouseholdIncome',  'annualHouseholdIncome'],
    ['familyMedical',          'familyMedical'],
    ['tobaccoUsage',           'tobaccoUsage'],
    ['ladderLifeId',       'ladderLifeQuotes.ladderId'],
    ['ladderLifeQuoteStatus',       'ladderLifeQuotes.quoteStatus', objectToString],
    ['ladderLifeApplies',       'ladderLifeQuotes.applies', objectArrayToString],
    ['ladderLifeQuotes',       'ladderLifeQuotes.quotes', objectArrayToString],
    ['mediaAlphaQuotesLastRefreshed', 'mediaAlphaQuotes.lastRefreshed'],
    ['mediaAlphaSelected',       'mediaAlphaQuotes.selected', objectArrayToString],
    ['mediaAlphaOffers',       'mediaAlphaQuotes.offers', objectArrayToString],

  ],

  'consumerMotorcycleInsurances': [
    ['accountId',              'accountId'],

    ['firstName',              'consumer.firstName'],
    ['lastName',               'consumer.lastName'],
    ['email',                  'consumer.email'],
    ['gender',                 'consumer.gender'],
    ['maritalStatus',          'consumer.maritalStatus'],
    ['dateOfBirth',            'consumer.dateOfBirth', getDate],
    ['cellPhone',              'consumer.cellPhone'],
    ['addressStatus',          'consumer.addressStatus'],
    ['street',                 'consumer.currentAddress.address1'],
    ['unit',                   'consumer.currentAddress.unit'],
    ['city',                   'consumer.currentAddress.city'],
    ['state',                  'consumer.currentAddress.state'],
    ['zipcode',                'consumer.currentAddress.zipcode'],
    ['country',                'consumer.currentAddress.country'],
    ['educationLevel',         'consumer.educationLevel'],
    ['providedCreditRating',   'consumer.creditInfo.providedCreditRating'],
    ['employmentStatus',       'consumer.employmentInfo.status'],
    ['militaryStatus',         'consumer.militaryStatus'],

    ['motorcycles',            'motorcycles', arrayToString],
    ['drivers',                'drivers', arrayToString],
    ['stage',                  'stage'],
    ['insured',                'insured'],
    ['insuredFor',             'insuredFor'],
    ['carrier',                'carrier'],
    ['expiration',             'expiration'],
    ['premium',                'premium'],
    ['frequency',              'frequency'],
    ['coverageLevel',          'coverageLevel'],
    ['comprehensive',          'comprehensive'],
    ['collision',              'collision'],
    ['bodilyInjuryLiability',  'bodilyInjuryLiability', objectToString],
    ['propertyDamageLiability', 'propertyDamageLiability'],
    ['unInsuredMotorist',      'unInsuredMotorist', objectToString],
    ['medicalPayments',        'medicalPayments'],
    ['totaledOption',          'totaledOption'],
    ['towing',                 'towing'],
    ['reshopStartDate',        'reshopStartDate'],
    ['requoteEnabled',         'requoteEnabled'],
    ['quoteCycle',             'quoteCycle'],
    ['completedOn',            'completedOn'],
    ['isCustomer',             'isCustomer'],
  ],

  'consumerBoatInsurances': [
    ['accountId',              'accountId'],

    ['firstName',              'consumer.firstName'],
    ['lastName',               'consumer.lastName'],
    ['email',                  'consumer.email'],
    ['gender',                 'consumer.gender'],
    ['maritalStatus',          'consumer.maritalStatus'],
    ['dateOfBirth',            'consumer.dateOfBirth', getDate],
    ['cellPhone',              'consumer.cellPhone'],
    ['addressStatus',          'consumer.addressStatus'],
    ['street',                 'consumer.currentAddress.address1'],
    ['unit',                   'consumer.currentAddress.unit'],
    ['city',                   'consumer.currentAddress.city'],
    ['state',                  'consumer.currentAddress.state'],
    ['zipcode',                'consumer.currentAddress.zipcode'],
    ['country',                'consumer.currentAddress.country'],
    ['educationLevel',         'consumer.educationLevel'],
    ['providedCreditRating',   'consumer.creditInfo.providedCreditRating'],
    ['employmentStatus',       'consumer.employmentInfo.status'],
    ['militaryStatus',         'consumer.militaryStatus'],

    ['boats',                  'boats', arrayToString],
    ['trailers',               'trailers', arrayToString],
    ['drivers',                'drivers', arrayToString],
    ['stage',                  'stage'],
    ['insured',                'insured'],
    ['insuredFor',             'insuredFor'],
    ['carrier',                'carrier'],
    ['expiration',             'expiration'],
    ['premium',                'premium'],
    ['frequency',              'frequency'],
    ['coverageLevel',          'coverageLevel'],
    ['comprehensive',          'comprehensive'],
    ['collision',              'collision'],
    ['bodilyInjuryLiability',  'bodilyInjuryLiability', objectToString],
    ['propertyDamageLiability', 'propertyDamageLiability'],
    ['unInsuredMotorist',      'unInsuredMotorist', objectToString],
    ['medicalPayments',        'medicalPayments'],
    ['totaledOption',          'totaledOption'],
    ['towing',                 'towing'],
    ['reshopStartDate',        'reshopStartDate'],
    ['requoteEnabled',         'requoteEnabled'],
    ['quoteCycle',             'quoteCycle'],
    ['completedOn',            'completedOn'],
    ['isCustomer',             'isCustomer'],
  ],

  'consumerRvInsurances': [
    ['accountId',              'accountId'],
    ['activeCampaignId',       'activeCampaignId'],
    ['utm_source',             'consumer.utm.utm_source'],
    ['utm_medium',             'consumer.utm.utm_medium'],
    ['utm_campaign',           'consumer.utm.utm_campaign'],
    ['deviceType',             'consumer.deviceType'],

    ['firstName',              'consumer.firstName'],
    ['middleName',             'consumer.middleName'],
    ['lastName',               'consumer.lastName'],
    ['email',                  'consumer.email'],
    ['dateOfBirth',            'consumer.dateOfBirth', getDate],
    ['cellPhone',              'consumer.cellPhone'],
    ['addressStatus',          'consumer.addressStatus'],
    ['currentAddress',         'consumer.currentAddress', addressAsString],
    ['educationLevel',         'consumer.educationLevel'],
    ['providedCreditRating',   'consumer.creditInfo.providedCreditRating'],
    ['employmentStatus',       'consumer.employmentInfo.status'],
    ['employmentIndustry',     'consumer.employmentInfo.industry'],
    ['employmentOccupation',   'consumer.employmentInfo.occupation'],
    ['militaryStatus',         'consumer.militaryStatus'],
    ['haveAutoInsurance',      'discounts.haveAutoInsurance'],
    ['yearsExperience',        'drivingInfo.rvInfo.yearsExperience'],
    ['ageFirstLicensed',       'drivingInfo.ageFirstLicensed'],
    ['driversLicenseState',    'drivingInfo.driversLicenseState'],
    ['licenseStatus',          'drivingInfo.licenseStatus'],
    ['maritalStatus',          'consumer.maritalStatus'],

    ['completedOn',            'completedOn'],
    ['num rvs',                'rvs', arrayLength],
    ['num drivers',            'drivers', arrayLength],
    ['stage',                  'stage'],
    ['insured',                'insured'],
    ['differentMailingAddress', 'differentMailingAddress'],
    ['desiredEffectiveDate',   'desiredEffectiveDate'],
  ],

  'consumerMortgageSolutions': [
    ['accountId',              'accountId'],

    ['firstName',              'consumer.firstName'],
    ['lastName',               'consumer.lastName'],
    ['email',                  'consumer.email'],
    ['gender',                 'consumer.gender'],
    ['maritalStatus',          'consumer.maritalStatus'],
    ['dateOfBirth',            'consumer.dateOfBirth', getDate],
    ['cellPhone',              'consumer.cellPhone'],
    ['addressStatus',          'consumer.addressStatus'],
    ['street',                 'consumer.currentAddress.address1'],
    ['unit',                   'consumer.currentAddress.unit'],
    ['city',                   'consumer.currentAddress.city'],
    ['state',                  'consumer.currentAddress.state'],
    ['zipcode',                'consumer.currentAddress.zipcode'],
    ['country',                'consumer.currentAddress.country'],
    ['educationLevel',         'consumer.educationLevel'],
    ['providedCreditRating',   'consumer.creditInfo.providedCreditRating'],
    ['employmentStatus',       'consumer.employmentInfo.status'],
    ['militaryStatus',         'consumer.militaryStatus'],

    ['homePurchase',          'homePurchase', objectToString],
    ['homeRefinance',         'homeRefinance', objectToString],
    ['loanModification',      'loanModification', objectToString],
    ['reverseMortgage',       'reverseMortgage', objectToString],
  ],

  'consumerCellPhonePlans': [
    ['accountId',              'accountId'],
    ['currentNetwork',         'currentNetwork'],
    ['carrierRating',          'carrierRating'],
    ['billAmount',             'billAmount'],
    ['numLines',               'numLines'],
    ['dataRequirements',       'dataRequirements'],
  ],

  'consumerStreamingPlans': [
    ['accountId',              'accountId'],
    ['billAmount',             'billAmount'],
    ['stage',                  'stage'],
    ['preferences',            'preferences', objectToString],
  ],

  'consumerInternetPlans': [
    ['accountId',              'accountId'],
    ['currentNetwork',         'currentNetwork'],
    ['billAmount',             'billAmount'],
    ['annualContract',         'annualContract'],
    ['expirationUnsure',       'expirationUnsure'],
    ['contractExpiration',     'contractExpiration'],
    ['numOnlineDevices',       'numOnlineDevices'],
    ['wfhOrRemoteLearning',    'wfhOrRemoteLearning'],
    ['wfhOrRemoteLearningNumDevices', 'wfhOrRemoteLearningNumDevices'],
    ['hdStreaming',            'hdStreaming'],
    ['hdStreamingNumDevices',  'hdStreamingNumDevices'],
    ['fourKStreaming',         'fourKStreaming'],
    ['fourKStreamingNumDevices', 'fourKStreamingNumDevices'],
    ['speedPoint',             'speedPoint'],
    ['recommendedSpeed',       'recommendedSpeed'],
    ['stage',                  'stage'],
    ['selectedPlan',            'selectedPlan', objectToString],
    ['completedOn',             'completedOn'],
  ],

  'consumerProperties': [
    ['accountId',              'accountId'],
    ['apn',                    'apn'],
    ['waitlisted',             'waitlisted'],
    ['support',                'support'],
    ['address',                'address', objectToString],
    ['value',                  'value'],
    ['purchaseDate',           'purchaseDate'],
    ['purchaseYear',           'purchaseYear'],
    ['residenceType',          'residenceType'],
    ['occupancyDuration',      'occupancyDuration'],
    ['partTimeRental',         'partTimeRental'],
    ['abnormalConditions',     'abnormalConditions', objectToString],
    ['dogs',                   'dogs', arrayToString],
    ['conductBusiness',        'conductBusiness'],
    ['structureDetails',       'structureDetails', objectToString],
    ['additionalStructureDetails', 'additionalStructureDetails', objectToString],
    ['interiorDetails',         'interiorDetails', objectToString],
    ['financingDetails',        'financingDetails', objectToString],
    ['updatesInLast15Years',    'updatesInLast15Years', objectToString],
  ],

  'smsTrackers': [
    ['accountId',              'accountId'],
    ['status',                 'status'],
    ['cellPhone',              'cellPhone'],
    ['accountType',            'accountType'],
    ['notificationType',       'notificationType'],
    ['message',                'message'],
    ['sid',                    'sid'],
  ],

  'emailTrackers': [
    ['accountId',              'accountId'],
    ['email',                  'email'],
    ['status',                 'status'],
    ['accountType',            'accountType'],
    ['notificationType',       'notificationType'],
    ['vertical',               'vertical'],
    ['templateId',             'templateId'],
    ['parameters',             'parameters', objectToString],
    ['sid',                    'sid'],
  ],

  'receivedSms': [
    ['cellNumber',             'cellNumber'],
    ['message',                'message'],
  ],

  'messageTrackers': [
    ['accountId',              'accountId'],
    ['accountType',            'accountType'],
    ['quoteId',                'quoteId'],
    ['vertical',               'vertical'],
    ['verticalId',             'verticalId'],
    ['state',                  'state'],
    ['status',                 'status'],
    ['type',                   'type'],
    ['email',                  'email', objectToString],
    ['text',                   'text', objectToString],
  ],

  'carrierInsuranceFilters': [
    ['carrierId',              'carrierId'],
    ['state',                  'state'],
    ['filter',                 'filter', objectToString],
  ],

  'urls': [
    ['url',                    'url'],
    ['type',                   'type'],
    ['code',                   'code'],
    ['hash',                   'hash'],
    ['accountId',              'accountId'],
    ['accountType',            'accountType'],
    ['numClicks',              'numClicks'],
    ['numUsage',               'numUsage'],
    ['clickedAt',              'clickedAt', arrayToString],
    ['usageAt',                'usageAt', arrayToString],
    ['token',                  'token'],
  ],

  'policies': [
    ['accountId',              'accountId'],
    ['vertical',               'vertical'],
    ['verticalId',             'verticalId'],
    ['policyNumber',           'policyNumber'],
    ['status',                 'status'],
    ['cycle',                  'cycle'],
    ['policyDetails',          'policyDetails', objectToString],
  ],

  'consumerMotorcycles': [
    ['accountId',              'accountId'],
    ['vin',                    'vin'],
    ['year',                   'year'],
    ['make',                   'make'],
    ['model',                  'model'],
    ['ownership',              'ownership'],
    ['primaryDriver',          'primaryDriver', objectToString],
    ['engineCC',               'engineCC'],
    ['purchaseYear',           'purchaseYear'],
    ['antilockBrakes',         'antilockBrakes'],
    ['modified',               'modified'],
    ['lojack',                 'lojack'],
  ],

  'consumerBoats': [
    ['accountId',              'accountId'],
    ['vin',                    'vin'],
    ['year',                   'year'],
    ['make',                   'make'],
    ['model',                  'model'],
    ['type',                   'type'],
    ['hullLength',             'hullLength'],
    ['hullMaterial',           'hullMaterial'],
    ['propulsionType',         'propulsionType'],
    ['numMotors',              'numMotors'],
    ['horsepower',             'horsepower'],
    ['enhancedPerformance',    'enhancedPerformance'],
    ['primaryUse',             'primaryUse'],
    ['ownership',              'ownership'],
    ['originalOwner',          'originalOwner'],
    ['coOwner',                'coOwner'],
    ['marketValue',            'marketValue'],
    ['trailer',                'trailer', objectToString],
  ],

  'consumerTrailers': [
    ['accountId',              'accountId'],
    ['vin',                    'vin'],
    ['year',                   'year'],
    ['make',                   'make'],
    ['model',                  'model'],
  ],
};

function arrayLength(arr: any[]): number {
  return Array.isArray(arr) ? arr.length : 0;
}

function arrayToString(arr: any[]): string {
  if (!_.isEmpty(arr) && Array.isArray(arr)) {
    return arr
      .map((value: any) => {
        if (value && typeof value === 'object') {
          return Array.isArray(value) ? arrayToString(value) : objectToString(value);
        } else {
          return `${value}`;
        }
      })
      .join()
      .replace(/,/g, '|');
  }

  return _.toString(arr);
}

function objectArrayToString(arr: any[]): string {
  let result: string = '';

  if (!_.isEmpty(arr) && Array.isArray(arr)) {
    arr.map((item: any) => {
      Object.keys(item).forEach((field: any) => {
        result += `${field}: ${objectToString(item[field])}|`;
      });
      result += '|';
    });
  }

  return result || _.toString(arr);
}

function objectToString(item: any): string {
  let result: string = '';

  if (!_.isEmpty(item) && _.isPlainObject(item)) {
    Object.keys(item).forEach((field: any) => {
      if (item[field] && typeof item[field] === 'object') {
        result += Array.isArray(item[field]) ? `${field}: ${arrayToString(item[field])}|` : `${field}: ${objectToString(item[field])}|`;
      } else {
        result += `${field}: ${item[field]}|`;
      }
    });
  }

  return result || _.toString(item);
}

function removeUnderscoresFromStr(str: string): string {
  while (str.includes('_')) {
    str = str.replace('_', ' ');
  }
  return str;
}

function getDate(date: string): string {
  return date ? parseDate(date).formatted : '';
}

function numMAOffers(mediaAlphaQuotes: any): any {
  if (mediaAlphaQuotes && mediaAlphaQuotes.offers) {
    return mediaAlphaQuotes.offers.length;
  }
  return 0;
}

function quoteCycle(value: number): string {
  if (value === 1) {
    return 'New quote request';
  } else {
    return `Re-shop quote cycle #${value - 1}`;
  }
}

function getBrokersQuoted(brokers: any[]): number {
  let quotedCounter: number = 0;
  if (brokers.length) {
    brokers.forEach((broker: any) => {
      if (['accepted', 'quoted'].includes(broker.status.toLowerCase())) {
        quotedCounter++;
      }
    });
  }
  return quotedCounter;
}

function numCarriersQuoted(rtQuotes: any): number {
  if (rtQuotes) {
    return rtQuotes.filter((c: any) => ['accepted', 'quoted'].includes(c.status)).length;
  }
  return 0;
}

function quoteType(type: string): string {
  return type === 'realtime' ? 'Realtime' : type === 'mediaAlpha' ? 'Media Alpha' : 'Manual';
}

function currentPremium(details: any): string | number | null {
  return details.vertical?.premium || details.vertical?.insurance?.premium || null;
}

function currentPremiumFrequency(details: any): string | null {
  const frequency = details?.vertical?.frequency;
  const frequencies: { monthly: boolean, yearly: boolean, sixmonth: boolean } = resolveFrequencyValues(frequency);
  const frequencyStr = frequencies.monthly ? 'month' : frequencies.yearly ? 'year' : frequencies.sixmonth ? '6 months' : null;
  return frequencyStr;
}

function firstAgentId(agentStatuses: any[]): any {
  if (agentStatuses && agentStatuses.length > 0) {
    return agentStatuses[0].by;
  }
  return undefined;
}

export function finalPricePremium(finalPriceInfo: any, index?: number): any {
  if (!finalPriceInfo?.isComplete) {
    return undefined;
  }

  let primary;
  if (index || index === 0) {
    primary = finalPriceInfo?.quoteDetails[index]?.premiumDetails?.find(pd => pd.isPrimary);
    if (!primary) {
      primary = finalPriceInfo?.quoteDetails[index]?.paymentPlans?.find(pp => pp.isPrimary);
    }
  } else {
    primary = finalPriceInfo.premiumDetails?.find(pd => pd.isPrimary);
    if (!primary) {
      primary = finalPriceInfo.paymentPlans?.find(pp => pp.isPrimary);
    }
  }

  if (!!primary) {
    return num2ViewString(primary.pifPremium || primary.totalPremium);
  }
}

export function finalPricePremiumType(finalPriceInfo: any, index?: number): any {
  if (!finalPriceInfo?.isComplete) {
    return undefined;
  }

  let primary;
  if (index || index === 0) {
    primary = finalPriceInfo?.quoteDetails[index]?.premiumDetails?.find(pd => pd.isPrimary);
    if (!primary) {
      primary = finalPriceInfo?.quoteDetails[index]?.paymentPlans?.find(pp => pp.isPrimary);
    }
  } else {
    primary = finalPriceInfo.premiumDetails?.find(pd => pd.isPrimary);
    if (!primary) {
      primary = finalPriceInfo.paymentPlans?.find(pp => pp.isPrimary);
    }
  }

  return primary?.type;
}

export function finalPriceInstallmentAmount(finalPriceInfo: any, index?: number): any {
  if (!finalPriceInfo?.isComplete) {
    return undefined;
  }

  let type = 'PIF';
  if (index || index === 0) {
    let primary = finalPriceInfo?.quoteDetails[index]?.premiumDetails?.find(pd => pd.isPrimary);
    if (!primary) {
      primary = finalPriceInfo?.quoteDetails[index]?.paymentPlans?.find(pp => pp.isPrimary);
      type = !!primary ? `${num2ViewString(primary.futurePayments)}` : '';
    }
  } else {
    let primary = finalPriceInfo.premiumDetails?.find(pd => pd.isPrimary);
    if (!primary) {
      primary = finalPriceInfo.paymentPlans?.find(pp => pp.isPrimary);
      type = !!primary ? `${num2ViewString(primary.futurePayments)}` : '';
    }
  }
  return type;
}

export function finalPriceNumPayments(finalPriceInfo: any, index?: number): any {
  if (!!finalPriceInfo?.isComplete) {
    let primary;
    if (index || index === 0) {
      primary = finalPriceInfo?.quoteDetails[index]?.paymentPlans?.find(pp => pp.isPrimary);
    } else {
      primary = finalPriceInfo.paymentPlans?.find(pp => pp.isPrimary);
    }
    return primary?.numPayments;
  }
  return undefined;
}

export function finalPriceDownPayment(finalPriceInfo: any, index?: number): any {
  if (!!finalPriceInfo?.isComplete) {
    let primary;
    if (index || index === 0) {
      primary = finalPriceInfo?.quoteDetails[index]?.paymentPlans?.find(pp => pp.isPrimary);
    } else {
      primary = finalPriceInfo.paymentPlans?.find(pp => pp.isPrimary);
    }
    return !!primary?.initialPayment ? num2ViewString(primary.initialPayment) : undefined;
  }
  return undefined;
}
