import { Component, OnDestroy, OnInit, Inject, PLATFORM_ID } from '@angular/core';
import { isPlatformBrowser, DOCUMENT } from '@angular/common';
import { SessionStorage } from 'ngx-webstorage';
import { CookieService } from 'ngx-cookie-service';
import { GuidService, AuthService, VersionCheckService } from '@services/index';
import { environment } from '@environments/environment';
import { Meta } from '@angular/platform-browser';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit, OnDestroy {
  public title: string = 'Squeeze Admin';
  @SessionStorage('session-id')
  public sessionId?: string | null;

  constructor(
    private meta: Meta,
    private _guid: GuidService,
    private cookieService: CookieService,
    public auth: AuthService,
    private versionCheckService: VersionCheckService,
    @Inject(PLATFORM_ID) private _platformId: Object,
    @Inject(DOCUMENT) private document: Document,
  ) {

    if (isPlatformBrowser(this._platformId)) {

      console.log({
        environment: environment.envName,
        production: environment.production,
        domain: environment.app_domain,
      });

      /* If the browser_uuid hasn't already been set... */
      if (this.document.cookie.indexOf('browser_uuid_set=1') === -1 || localStorage.getItem('browser_uuid') == null) {
        /* Generate a UUID, and assign it to the browser_id custom dimension */
        const browser_uuid = this._guid.guid();
        console.log('New browserId created', browser_uuid);
        /* Store browser_uuid in local storage */
        localStorage.setItem('browser_uuid', browser_uuid);
        /* Set a cookie so we won't override the UUID we just set */
        this.cookieService.set('browser_uuid_set', '1');

      } else {
        console.log('Existing browserId', localStorage.getItem('browser_uuid'));
      }

      /* create a session id if not set */
      if (!this.sessionId) {
        this.sessionId = this._guid.guid();
        console.log('New session created', this.sessionId);
      } else {
        console.log('Existing sessionId', this.sessionId);
      }

    }

    // Add noindex meta tag to all environments
    if (!this.meta.getTag("name='robots'")) {
      this.meta.addTag({ name: 'robots', content: 'noindex' });
    }
    if (!this.meta.getTag("name='goolebot'")) {
      this.meta.addTag({ name: 'goolebot', content: 'noindex' });
    }

  }

  ngOnDestroy(): void {
    this.sessionId = null;
  }

  ngOnInit(): void {
    this.versionCheckService.initVersionCheck();
  }
}
