import { Injectable } from '@angular/core';
// @ts-ignore
import * as dayjs from 'dayjs';

@Injectable()
export class DaterangeService {

  private dateFormats = ['MM/DD/YYYY', 'MM/YYYY', 'YYYY'];
  private dateTimeFormats = ['MM/DD/YYYY hh:mm a', 'MM/YYYY hh:mm a', 'YYYY hh:mm a'];

  public getDateTimeRange(minDate: string, minTime: string, maxDate: string, maxTime: string): any {
    const dateRange: any = {
      minDateTime: null,
      maxDateTime: null,
    };

    if (minDate) {
      if (minTime) {
        dateRange.minDateTime = dayjs(minDate + ' ' + minTime, this.dateTimeFormats).format('YYYY-MM-DDTHH:mm:ss');
      } else {
        dateRange.minDateTime = dayjs(minDate, this.dateFormats).format('YYYY-MM-DDTHH:mm:ss');
      }
    }
    if (maxDate) {
      if (maxTime) {
        dateRange.maxDateTime = dayjs(maxDate + ' ' + maxTime, this.dateTimeFormats).format('YYYY-MM-DDTHH:mm:ss');
      } else {
        dateRange.maxDateTime = dayjs(maxDate, this.dateFormats).format('YYYY-MM-DDTHH:mm:ss');
      }
    }
    return dateRange;
  }

}
