import { Injectable } from '@angular/core';
import { RequestService } from '../services/request.service';
import { environment } from '../../environments/environment';
import {
  ICarrier,
} from '@common/interfaces/index';

@Injectable()
export class CarriersService {

  private internalUrl: string = environment.internal_api_url;

  public totalCount: number = 0;

  constructor(
    private request: RequestService,
  ) {
  }

  public getAutoCarriers(): any {
    return this.request.send('get', `${this.internalUrl}/auto/carriers`);
  }

  public new_GetCarriers(options?: any): any {
    return this.request.send('get', 'api/carriers', options).toPromise()
      .then((res: any): any => {
        if (res && res.totalCount) {
          this.totalCount = res.totalCount;
        }

        return res;
      });
  }

  public createCarriers(options: any): Promise<{} | ICarrier> {
    return this.request.send('post', 'api/carriers', options).toPromise();
  }

  public removeCarrier(carrier: ICarrier): any {
    return this.request.send('delete', `api/carriers/${carrier._id}`).toPromise();
  }

  public editCarrier(carrier: ICarrier): any {
    return this.request.send('post', 'api/carriers/update', carrier).toPromise();
  }
}
