// @ts-ignore
import * as dayjs from 'dayjs';
import * as json2csv from 'json2csv';
import n from 'normalize-data';
import { Injectable } from '@angular/core';
import {
  finalPriceDownPayment,
  finalPriceInstallmentAmount,
  finalPriceNumPayments,
  finalPricePremium,
  finalPricePremiumType,
} from '@common/constants/export-mapping';
import * as utc from 'dayjs/plugin/utc';
import * as timezone from 'dayjs/plugin/timezone';

// Extend dayjs with the necessary plugins
dayjs.extend(utc);
dayjs.extend(timezone);

@Injectable()
export class ExportService {
  private slug: string = 'Export';

  public handleRawDataExport(rawData: any, exportMapping: any, slug: string = 'Export'): void {
    try {

      // check if results were streamed as JSON string
      if (typeof rawData === 'string') {
        // streamed results need to be parsed & normalized
        // for the selected collection
        rawData = JSON.parse(rawData);
      }

      // normalize raw data for export
      // console.log('rawData', rawData); // log rawData before normalization
      this.slug = slug;
      const jsonArray = this.normalizeRawDataResultsForExport(rawData, exportMapping);

      // export to normalized JSON to CSV
      this.exportToCSV(jsonArray);

    } catch (e) {
      console.warn(e);
      alert('Something went wrong (handleRawDataExport)');
    }
  }

  private normalizeRawDataResultsForExport(results: any[], exportMapping: any): Array<any> {
    let jsonArray: Array<any> = []; // json array to be exported to CSV

    try {
      // normalize each object
      results.forEach((doc: any) => {
        if (doc?.finalPriceInfo?.quoteDetails.length > 0) {
          doc.finalPriceInfo.quoteDetails.forEach((d, index) => {
            doc[`FPPremium${index + 1}`] = finalPricePremium(doc.finalPriceInfo, index);
            exportMapping.push([`FP Premium ${index + 1}`, `FPPremium${index + 1}`]);

            doc[`FPDownPayment${index + 1}`] = finalPriceDownPayment(doc.finalPriceInfo, index);
            exportMapping.push([`FP Down Payment ${index + 1}`, `FPDownPayment${index + 1}`]);

            doc[`FPInstallmentAmount${index + 1}`] = finalPriceInstallmentAmount(doc.finalPriceInfo, index);
            exportMapping.push([`FP Installment Amount ${index + 1}`, `FPInstallmentAmount${index + 1}`]);

            doc[`FPNumberOfInstallment${index + 1}`] = finalPriceNumPayments(doc.finalPriceInfo, index);
            exportMapping.push([`FP Number of Installment ${index + 1}`, `FPNumberOfInstallment${index + 1}`]);

            doc[`FPPaymentMethod${index + 1}`] = finalPricePremiumType(doc.finalPriceInfo, index);
            exportMapping.push([`FP Payment Method ${index + 1}`, `FPPaymentMethod${index + 1}`]);
          });
        } else {
          exportMapping.push(['FP Premium 1', 'finalPriceInfo', finalPricePremium]);
          exportMapping.push(['FP Down Payment 1', 'finalPriceInfo', finalPriceDownPayment]);
          exportMapping.push(['FP Installment Amount 1', 'finalPriceInfo', finalPriceInstallmentAmount]);
          exportMapping.push(['FP Number of Installment 1', 'finalPriceInfo', finalPriceNumPayments]);
          exportMapping.push(['FP Payment Method 1', 'finalPriceInfo', finalPricePremiumType]);
        }
        const normalized = n.normalize(doc, exportMapping);
        jsonArray.push(normalized);
      });
      // remove commas in values
      jsonArray.forEach(o => {
        Object.keys(o).forEach(k => {
          if (typeof o[k] === 'string') {
            o[k] = o[k].replace(/,/g, '');
          }
        });
      });
      // sort by createdAt descending
      // tslint:disable-next-line:typedef
      jsonArray = jsonArray.sort(function (current, next) {
        return dayjs.utc(current.createdAt).diff(dayjs.utc(current.createdAt));
      });
    } catch (e) {
      console.warn(e);
      alert('Something went wrong (normalizeRawDataResultsForExport)');
    }

    return jsonArray;
  }

  private exportToCSV(jsonArray: any): void {
    try {
      const csv = json2csv.parse(jsonArray, { defaultValue: '', quote: '' });
      const downloadLink = document.createElement('a');
      const blob = new Blob(['\ufeff', csv]);
      const url = URL.createObjectURL(blob);

      downloadLink.href = url;
      downloadLink.download = `${this.slug}_${dayjs().format()}.csv`;

      document.body.appendChild(downloadLink);
      downloadLink.click();
      document.body.removeChild(downloadLink);

    } catch (e) {
      console.warn(e);
      alert('Something went wrong (exportToCSV)');
    }
  }
}
