//@ts-ignore
import * as accounting from 'accounting-js';

/**
 * Turns a number value to a money string value (for display)
 * @param value
 * @param decimals
 */
export function num2Money(value: number, precision: number = 0): string | undefined {
  if (typeof value === 'undefined') {
    return value;
  }
  if (typeof accounting === 'undefined') {
    return value + '';
  } else {
    return accounting.formatMoney(value, {
      symbol: '$',
      decimal: '.',
      thousand: ',',
      precision,
    });
  }
}

/**
 * Formats number value to string with decimals and commas (for display)
 * @param value
 * @param precision
 */
export function num2ViewString(value: number, precision: number = 0): string | undefined {
  if (typeof value === 'undefined') {
    return value;
  }
  if (typeof accounting === 'undefined') {
    return value + '';
  } else {
    return accounting.formatNumber(value, {
      precision,
      thousand: ',',
      decimal: '.',
    });
  }
}

/**
 * Formats a number string or number to a number type
 * @param value
 */
export function string2Num(value: any): number | undefined {
  if (typeof value === 'undefined') {
    return value;
  }
  if (typeof accounting === 'undefined') {
    return Number(value);
  } else {
    return accounting.parse(value);
  }
}

/**
 * Formats number value as string (no commas)
 * @param value
 */
export function num2String(value: number | string): string | undefined {
  if (typeof value === 'undefined') {
    return value;
  }
  if (typeof accounting === 'undefined') {
    console.warn('unable to use accounting-js');
    return value + '';
  } else {
    return typeof value === 'number' ? accounting.unformat(value).toString() : value;
  }
}
