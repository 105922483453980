import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class UseragentLookupService {

  constructor() { }

  // @TODO mobile browsers not currenly captured in regex, consider using https://github.com/lancedikson/bowser
  //

  /* https://stackoverflow.com/questions/2400935/browser-detection-in-javascript */
  public saywho(ua: any): string {
    if (!ua) { return 'N/A'; }
    let tem,
    M = ua.match(/(opera|chrome|safari|firefox|msie|trident(?=\/))\/?\s*(\d+)/i) || [];
    if (/trident/i.test(M[1])) {
        tem =  /\brv[ :]+(\d+)/g.exec(ua) || [];
        return 'IE ' + (tem[1] || '');
    }
    if (M[1] === 'Chrome') {
        tem = ua.match(/\b(OPR|Edge)\/(\d+)/);
        if (tem != null) { return tem.slice(1).join(' ').replace('OPR', 'Opera'); }
    }
    M = M[2] ? [M[1], M[2]] : [navigator.appName, navigator.appVersion, '-?'];
    if ((tem = ua.match(/version\/(\d+)/i)) != null) { M.splice(1, 1, tem[1]); }
    return M.join(' ');
  }
}
