import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from './auth.service';

@Injectable()
export class AuthGuardService  {

  constructor(public auth: AuthService, public router: Router) {}

  canActivate(): Promise<boolean> {
    return new Promise((resolve) => {
      this.auth.isAuthenticated()
        .then((isAuthenticated) => {
          console.log('isAuthenticated?', isAuthenticated);
          if (!isAuthenticated) {
            this.router.navigate(['/auth/signin']);
          }
          resolve(isAuthenticated);
        })
        .catch(err => {
          console.warn(err);
          this.router.navigate(['/auth/signin']);
          resolve(false);
        });
      });
  }

}
