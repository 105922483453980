import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import {
  AbstractControl,
  ValidationErrors,
} from '@angular/forms';
import { environment } from '@environments/environment';
import { EmailVerifyService } from '@services/email-verify.service';

@Injectable()
export class EmailAsyncValidatorService {
  invalidEmail = {
    invalid: true,
  } as ValidationErrors;

  invalidFormat = {
    format: true,
  } as ValidationErrors;

  constructor(
    private emailVerify: EmailVerifyService,
  ) {
    this.validateEmail = this.validateEmail.bind(this);
  }

  public validateEmail(control: AbstractControl): Promise<ValidationErrors | null> | Observable<ValidationErrors | null> {
    const email = control.value;
    const isFormatValid = this.checkEmailFormat(email);

    if (isFormatValid && environment.production) {
      return this.emailVerify.verify(encodeURIComponent(email)).then((response: any) =>
        response && response.valid ? null : this.invalidEmail).catch(() => this.invalidEmail);
    } else {
      return new Promise(resolve => {
        resolve(isFormatValid ? null : this.invalidFormat);
      });
    }
  }

  private checkEmailFormat(email: string): boolean {
    const regex = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

    return regex.test(email);
  }
}
