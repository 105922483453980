import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { RequestService } from '@services/request.service';
import { IRequestOptions } from '@common/interfaces';
import { IUrlCreationData, IUrlCreationResponse } from '@common/interfaces/urls.interface';

@Injectable()
export class UrlsService {

  constructor(
    private request: RequestService,
  ) { }

  public getAll(params: any = {}): Observable<any> {
    params.limit = params.limit || 100;
    return this.request.send('get', '/api/urls', params);
  }

  getAllForExport(params: IRequestOptions = {}): Observable<any> {
    const options = {
      ...params,
      reportProgress: true,
      responseType: 'text',
    };

    return this.request.send('get', '/api/urls', options) as Observable<any>;
  }

  public createUrl(data: IUrlCreationData): Promise<IUrlCreationResponse> {
    return this.request.send('post', '/api/urls', data).toPromise() as Promise<IUrlCreationResponse>;
  }

  public deleteUrl(code: string): Promise<any> {
    return this.request.send('delete', `/api/urls/${code}`).toPromise();
  }
}
