export function ucFirst(value: string): string {
  try {
    if (value) {
      return value.charAt(0).toUpperCase() + value.slice(1).toLowerCase();
    }
    return value;
  } catch (_) {
    return value;
  }
}

export function ucFirstMultipleWords(value: string): string {
  // Useful for city names that can be more than one word long, will make each word start with an uppercase / capital letter.
  try {
    if (value) {
      return value.split(' ').map(ucFirst).join(' ');
    } else return '';
  } catch (_) {
    return value;
  }
}

export function underscoreToCapitalized(value: string): string {
  return value.split('_').map(ucFirst).join(' ');
}

export function pluralize(value: string, count: number): string {
  return `${value + (count > 1 ? 's' : '') }`;
}
