import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '@environments/environment';

export interface IPLookUp {
  as: string; // AS number/name
  city: string;
  country: string;
  countryCode: string;
  isp: string;
  lat: number;
  lon: number;
  org: string;
  query: string;
  region: string; // state abbr
  regionName: string; // state
  status: string; // success or .. ?
  timezone: string;
  zip: string;
}

@Injectable()
export class IpLookupService {

  // tslint:disable-next-line:typedef
  private apiUrl = '/api/ip-lookup';

  constructor(private http: HttpClient) { }

  lookUp(ip: string): Promise<any> {
    if (ip && environment.production) {
      ip = ip.split(',')[0];

      return this.http.get(`${this.apiUrl}/${ip}`).toPromise()
      .then(response =>
        response as IPLookUp).catch(error =>
          console.warn('IP lookup error', error),
        );
    } else {
      return new Promise((resolve) => {
        resolve(null);
      });
    }
  }

}
