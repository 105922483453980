import { Injectable } from '@angular/core';
import { RequestService } from '@services/request.service';
import { environment } from '@environments/environment';

@Injectable()
export class AuthService {

  get admin(): any {
    const account = localStorage.getItem('account');
    return account ? JSON.parse(account) : null;
  }
  set admin(value: any) {
    localStorage.setItem('account', JSON.stringify(value));
  }

  constructor(
    private request: RequestService,
    ) {}

  public isAuthenticated(): Promise<any> {
    if ((this.admin || {}).jwt) {
      return this.request.promise('get', '/api/auth/admin')
        .then(response => {
          // Update user account
          this.admin.account = response;
          return true;
        })
        .catch(errorResponse => {
          // JWT must be expired or invalid, clear user from local storage
          console.warn('no admin found', errorResponse);
          this.admin = null;
          return false;
        });
    } else {
      return Promise.resolve(false);
    }

  }

  public getAccountId(): string | undefined {
    if (this.admin) {
      return this.admin.account.id;
    }
    return undefined;
  }

  public hasDeletePermission(): boolean {
    if (environment.envName !== 'prod') {
      return true;
    }
    if (this.admin) {
      const accountIds: any = [
        '5cb4daf117dfc400306d36bb', // Phil Wiebe
        '6128df9fd93a5b0028c694a1', // Camila
        '60994faef96f380020cda55f', // Maria Claudia
        '61aa414cdb79f30021dae5d3', // Sissi Medina
        '5d01310b77d7820029fd77e2', // Maria Neira
      ];
      if (accountIds.includes(this.admin.account.id)) {
        return true;
      }
    }
    return false;
  }
  public logout(): Promise<any> {

    return this.request.promise('post', '/api/auth/logout')
        .then(response => {
          localStorage.clear();
          return response;
        })
        .catch(err => err);
  }

}
