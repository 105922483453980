import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { RequestService } from './request.service';

@Injectable()
export class TrackersService {
  constructor(private request: RequestService) {
  }

  public getAllTrackers(options: any = {}): Observable<any> {
    return this.request.send('get', '/api/trackers', options).pipe(catchError(e => throwError(e)));
  }

  public getTracker(options: any): Observable<any> {
    return this.request.send('get', '/api/trackers', options).pipe(catchError(e => throwError(e)));
  }
}
