import { Injectable } from '@angular/core';
import { RequestService } from '@services/request.service';
import {
  BUYER_MOD,
  CONSUMER_MOD,
  QUOTES_MOD,
} from '@constants';

@Injectable()
export class AdminNotesService {

  constructor(
    private request: RequestService,
  ) { }

  private choiceMode(mode: string | undefined): string {
    switch (mode) {
      case BUYER_MOD:
        return 'buyer';

      case CONSUMER_MOD:
        return 'consumer';

      case QUOTES_MOD:
        return 'quotes';

      default:
        return 'buyer';
    }
  }

  public getAllNotes(accountId: string | number, mode?: string, include_names: boolean = false): Promise<any> {
    return this.request.promise('get', `/api/admin/notes/${this.choiceMode(mode)}/${accountId}/all/`, { include_names })
      .then(response =>
        response.results);
  }

  public createNote(accountId: string | number, note: string, mode?: string): Promise<any> {
    return this.request.promise('post', `/api/admin/notes/${this.choiceMode(mode)}/${accountId}/create/`, { note })
      .then(response =>
        response);
  }

  public deleteNote(accountId: string | number, noteId: string, mode?: string): Promise<any> {
    return this.request.promise('delete', `/api/admin/notes/${this.choiceMode(mode)}/${accountId}/delete/${noteId}`)
      .then(response =>
        response);
  }

  public editNote(accountId: string | number, noteId: string, note: string, mode?: string): Promise<any> {
    return this.request.promise('post', `/api/admin/notes/${this.choiceMode(mode)}/${accountId}/edit/${noteId}`, { note })
      .then(response =>
        response);
  }
}
