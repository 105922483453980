import {
  IRealtimeQuote as RealtimeQuote,
  IQuoteDetailsResponse,
  IManualQuote as ManualQuote,
  TQuotePeriod,
  IRealtimeQuotePremiumDetails,
} from '@common/interfaces/quotes.interface';
import {
  ICarrier as ManualCarrier,
  IRaterCarrier as RealtimeCarrier,
} from '@common/interfaces/carrier.interface';
import { string2Num, num2ViewString } from './number.utils';
import { sort } from './sort.utils';
import * as dayjs from 'dayjs';
import { IConsumerStatusVerticalQuoteDetails } from '@common/interfaces';
import { TQuoteFrequency } from '../interfaces/status.interface';

/** Possible "quote" object types we could get from APIs */
export type QuoteType = IConsumerStatusVerticalQuoteDetails | IQuoteDetailsResponse | RealtimeQuote | ManualQuote;

export function getCarrierLogo(carrier: RealtimeCarrier | ManualCarrier): string {
  return (carrier as RealtimeCarrier)?.logoUrl || (carrier as ManualCarrier)?.logo;
}

export function getCarrierId(quote: Partial<ManualQuote> | Partial<RealtimeQuote>): number | null {
  const id = (quote as RealtimeQuote)?.carrier?.ID;
  if (!id) {
    return null;
  }
  return string2Num(id) || null;
}

export function getCarrierName(quote: Partial<ManualQuote> | Partial<RealtimeQuote>): string {
  if (!quote) {
    return 'Unknown';
  }
  return (quote as ManualQuote).details?.carrier || (quote as RealtimeQuote)?.carrier?.Name;
}

export function isRealTimeQuote(quote: Partial<ManualQuote> | Partial<RealtimeQuote>): boolean {
  return !!(quote as RealtimeQuote).quoteData && (quote as RealtimeQuote).type === 'realtime';
}

export function isManualQuote(quote: Partial<ManualQuote> | Partial<RealtimeQuote>): boolean {
  return quote && !!(quote as ManualQuote).details;
}

export function realTimeQuoteSort(quotes: RealtimeQuote[] = [], frequency: TQuotePeriod = '6 MONTHS'): RealtimeQuote[] {
  return quotes.slice().sort((a: RealtimeQuote, b: RealtimeQuote) => {
    const monthlyPremiumA = getRealtimeQuotePremium(a, frequency);
    const monthlyPremiumB = getRealtimeQuotePremium(b, frequency);
    return sort(monthlyPremiumA, monthlyPremiumB, 'asc');
  });
}
export function realTimeQuoteAnnualAnd6MonthSort(quotes: RealtimeQuote[] = []): RealtimeQuote[] {
  const annualQuotes = realTimeQuoteSort(quotes, 'ANNUALLY');
  const sixMonthQuotesWithoutRepeats = realTimeQuoteSort(quotes, '6 MONTHS').filter(quote => !annualQuotes.includes(quote));
  return annualQuotes.concat(sixMonthQuotesWithoutRepeats);

}
export function manualQuoteSort(quotes: ManualQuote[] = [], frequency: TQuotePeriod = '6 MONTHS'): ManualQuote[] {
  return quotes.slice().sort((a: ManualQuote, b: ManualQuote) => {
    const monthlyPremiumA = getManualQuotePremium(a, frequency);
    const monthlyPremiumB = getManualQuotePremium(b, frequency);
    const val = sort(monthlyPremiumA, monthlyPremiumB, 'asc');
    return val;
  }) as ManualQuote[];
}

export function manualQuoteAnnualAnd6MonthSort(quotes: ManualQuote[] = []): ManualQuote[] {
  const annualQuotes = manualQuoteSort(quotes, 'ANNUALLY');
  const sixMonthQuotesWithoutRepeats = manualQuoteSort(quotes, '6 MONTHS').filter(quote => !annualQuotes.includes(quote));
  return annualQuotes.concat(sixMonthQuotesWithoutRepeats);

}

export function resolveFrequencyValues(frequency: string = '6 MONTHS'): { monthly: boolean, yearly: boolean, sixmonth: boolean } {
  const freq = frequency?.toLowerCase() || '6';
  const monthly = freq.indexOf('month') > -1 && freq.indexOf('6') === -1;
  const yearly = freq.indexOf('year') > -1 || freq.indexOf('annual') > -1 || freq.indexOf('yr') > -1;
  const sixmonth = freq.indexOf('6') > -1;
  return { monthly, yearly, sixmonth };
}

export function getSavingsPerMonth(currentPremium: number, currentFrequency: string, newPremium: number, newFrequency: string): string {
  // Finds the monthly Savings

  /* Returns monthly savings as a formatted string with no decimals.
    Returns positive, negative, and zero values as strings.
    Returns null if missing valid values for newPremium or currentPremium,
    because does not calculate savings accurately without both premiums. */
  if (!currentPremium || currentPremium === 0 || !newPremium ) {
    return 'Unknown';
  }
  const newMonthlyCost = getPremium('MONTHLY', newFrequency, newPremium);
  const currentMonthlyCost = getPremium('MONTHLY', currentFrequency, currentPremium);
  const savings = currentMonthlyCost - newMonthlyCost;
  return Math.floor(savings).toFixed(0);
}

export function getSavingsPerMonthUsingDates(
  oldPremium: number,
  oldFrequency: string,
  newPremium: number,
  newEffectiveDate: dayjs.Dayjs,
  newExpirationDate: dayjs.Dayjs ): string {
  // Finds the monthly Savings

  /* Returns monthly savings as a formatted string with no decimals.
    Returns positive, negative, and zero values as strings.
    Returns null if missing valid values for newPremiuqm or currentPremium,
    because does not calculate savings accurately without both premiums. */
  if (!oldPremium || oldPremium === 0 || !newPremium ) {
    return 'Unknown';
  }
  const oldMonthlyCost = getPremium('MONTHLY', oldFrequency, oldPremium);
  //@ts-ignore
  const newMonths = dayjs(newEffectiveDate).diff(newExpirationDate, 'M');
  const newMonthlyCost = newPremium / newMonths;
  const savings = oldMonthlyCost - newMonthlyCost;
  return savings.toFixed(0);
}

export function getPercentSavings(currentPremium: number, currentFrequency: string, newPremium: number, newFrequency: string): number {
  // Finds the percent savings

  /* Returns percent savings rounded to the nearest integer.
    Returns positive, negative, and zero values.
    Returns null if missing valid values for newPremium or currentPremium,
    because does not calculate savings accurately without both premiums. */
  if (!currentPremium || currentPremium === 0 || !newPremium ) {
    return 0;
  }
  const newMonthlyCost = getPremium('MONTHLY', newFrequency, newPremium);
  const currentMonthlyCost = getPremium('MONTHLY', currentFrequency, currentPremium);
  const percent = 100 * ( currentMonthlyCost - newMonthlyCost ) / currentMonthlyCost;
  return Math.round(percent);
}

export function quoteFrequencyToQuotePeriod(freq: TQuoteFrequency): TQuotePeriod {
  switch (freq) {
    case 'monthly':
      return 'MONTHLY';

    case 'yearly':
      return 'ANNUALLY';

    default:
    case '6_months':
      return '6 MONTHS';
  }
}

export function getManualQuotePremium(quote: ManualQuote, frequency: TQuotePeriod = '6 MONTHS'): number {
  const mp = quote?.details.monthly_premium || 0;
  const smp = quote?.details['6_months_premium'] || 0;
  const yp = quote?.details.yearly_premium || 0;
  switch (frequency) {
    case 'MONTHLY':
      const mo = mp;
      const sixmo = smp / 6;
      const twelvemo = yp / 12;
      return twelvemo || sixmo || mo;
    case 'ANNUALLY':
      return yp || smp * 2 || mp * 12;
    default:
      return smp || mp * 6 || yp / 2;
  }
}

export function getRealtimeQuotePremium(quote: RealtimeQuote, frequency: TQuotePeriod = '6 MONTHS'): number {
  const preDetails = quote?.quoteData?.quoteDetails?.premiumDetails;
  const details = Array.isArray(preDetails) ? preDetails[0] : preDetails;
  const premium = string2Num(details?.amount) || 0;
  const term = string2Num(details?.term) || 6;
  switch (frequency) {
    case 'MONTHLY':
      return premium / term;
    case 'ANNUALLY':
      // Q: what are possible values for term?
      // right now just trying to cover what makes sense
      return term === 6 ? premium * 2 :
        term === 12 ? premium :
          term === 1 ? premium * 12 :
            term === 24 ? premium / 2 :
              term === 18 ? premium * 2 / 3 :
                premium;
    case '6 MONTHS':
      // 6 MONTHS also should be covered in case of only annual exist
      return term === 6 ? premium :
        term === 12 ? premium / 2 :
          term === 1 ? premium * 6 :
            term === 24 ? premium / 4 :
              term === 18 ? premium / 3 :
                premium;
    default:
      return premium;
  }
}

export function getPremium(desiredFrequency: TQuotePeriod = '6 MONTHS', frequency: string = '6', premium: string | number): number {
  const newpremium: number = string2Num(premium) || 0;
  const { monthly, sixmonth } = resolveFrequencyValues(frequency);
  switch (desiredFrequency) {
    case 'ANNUALLY':
      return monthly ? newpremium * 12 : sixmonth ? newpremium * 2 : newpremium;
    case 'MONTHLY':
      return monthly ? newpremium : sixmonth ? newpremium / 6 : newpremium / 12;
    case '6 MONTHS':
    default:
      return monthly ? newpremium * 6 : sixmonth ? newpremium : newpremium / 2;
  }
}

export function isStatusQuoteDetails(quote: QuoteType): boolean {
  return !!(quote as any)?.lowestFrequency;
}

export function isQuoteDetailsResponse(quote: QuoteType): boolean {
  return !!(quote as any)?.quote;
}

export function getPremiumAsMonthly(quote: QuoteType): number {
  if (isStatusQuoteDetails(quote)) {
    const statusQuote = quote as IConsumerStatusVerticalQuoteDetails;
    return getPremium('MONTHLY', statusQuote.lowestFrequency, statusQuote.lowestPremium);
  }

  if (isQuoteDetailsResponse(quote)) {
    const quoteDetails = (quote as IQuoteDetailsResponse).quote;

    if (isRealTimeQuote(quoteDetails)) {
      const premiumDetails = getLowestPremiumDetails(quoteDetails as RealtimeQuote);
      return getPremium('MONTHLY', premiumDetails.term, premiumDetails?.amount);
    }

    if (isManualQuote(quoteDetails)) {
      const premiumData = getManualQuotePremiumByPriority(quoteDetails as ManualQuote);
      return getPremium('MONTHLY', premiumData.term, premiumData?.premium);
    }
  }

  if (isRealTimeQuote(quote as any)) {
    const premiumDetails = getLowestPremiumDetails(quote as RealtimeQuote);
    return getPremium('MONTHLY', premiumDetails?.term, premiumDetails?.amount);
  }

  if (isManualQuote(quote as any)) {
    const premiumData = getManualQuotePremiumByPriority(quote as ManualQuote);
    return getPremium('MONTHLY', premiumData.term, premiumData.premium);
  }

  return 0;
}

export function getLowestPremiumDetails(quoteDetails: RealtimeQuote): IRealtimeQuotePremiumDetails {
  const quoteData = quoteDetails?.quoteData;
  const premiumDetails = quoteData?.quoteDetails?.premiumDetails;
  const details = Array.isArray(premiumDetails) ?
    (premiumDetails as IRealtimeQuotePremiumDetails[])
      //@ts-ignore
      .reduce((prev, curr) => string2Num(prev.amount) < string2Num(curr.amount) ? prev : curr) : premiumDetails;
  return details;
}

export function getManualQuotePremiumByPriority(quote: ManualQuote): { term: string, premium: number } {
  if (quote.details.yearly_premium) {
    return { term: 'yearly', premium: quote.details.yearly_premium };
  }
  if (quote.details['6_months_premium']) {
    return { term: '6', premium: quote.details['6_months_premium'] };
  }
  if (quote.details.monthly_premium) {
    return { term: 'monthly', premium: quote.details.monthly_premium };
  }
  return { term: 'Unknown', premium: 0 };
}

export function formatPerPersonPerAccident(amountPerPerson: number, amountPerAccident: number): string {
  const perPerson = num2ViewString(amountPerPerson);
  const perAccident = num2ViewString(amountPerAccident);
  if (!perPerson || !perAccident) {
    return 'Unknown';
  }
  return `$${perPerson},000/$${perAccident},000`;
}
