import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { provideHttpClient, withInterceptorsFromDi } from '@angular/common/http';
import { CookieService } from 'ngx-cookie-service';

import { IpLookupService } from '@services/ip-lookup.service';
import { UseragentLookupService } from '@services/useragent-lookup.service';
import { RequestService } from '@services/request.service';
import { EmailAsyncValidatorService } from '@common/index';
import { DaterangeService } from '@services/daterange.service';
import { CarriersService } from '@services/carriers.service';
import { ExportService } from '@services/export.service';
import { PromoService } from '@services/promo.service';
import { MessagesService } from '@services/messages.service';

import { AppMaterialModule } from '../material.module';

import { LoaderComponent } from './loader/loader.component';
import { DateTimeInputComponent } from './datetime-input/datetime-input.component';
import { TextInputComponent } from './text-input/text-input.component';
import { PasswordInputComponent } from './password-input/password-input.component';
import { FieldErrorComponent } from './field-error/field-error.component';
import { TabsMenuComponent } from './tabs-menu/tabs-menu.component';
import { SummaryItemComponent } from './summary-item/summary-item.component';
import { FilterToggleComponent } from './filter-toggle/filter-toggle.component';
import { DatepickerFilterComponent } from './datepicker-filter/datepicker-filter.component';
import { DatetimeQuickFilterComponent } from './datetime-quick-filter/datetime-quick-filter.component';
import { MongoFilterInputComponent } from './mongo-filter-input/mongo-filter-input.component';
import { FiltersPanelComponent } from './filters-panel/filters-panel.component';
import { ModalComponent } from './modal/modal.component';
import { ConfirmModalComponent } from './confirm-modal/confirm-modal.component';
import { SwitcherComponent } from './switcher/switcher.component';
import { SearchPanelComponent } from './search-panel/search-panel.component';
import { DashboardPanelComponent } from './dashboard-panel/dashboard-panel.component';
import { TextareaComponent } from './textarea/textarea.component';
import { NoteComponent } from './note/note.component';
import { AdminNotesComponent } from './admin-notes/admin-notes.component';
import { DeleteEntityComponent } from './delete-entity/delete-entity.component';
import { SelectComponent } from './select/select.component';
import { LazyLoadComponent } from './lazy-load/lazy-load.component';
import { ConsumerContactCardComponent } from './consumer-contact-card/consumer-contact-card.component';
import { TerminalComponent } from './terminal/terminal.component';
import { FindFilterComponent } from './find-filter/find-filter.component';

import { Ms2DurationPipe } from './pipes/ms2duration.pipe';
import {
  AddressPipe,
  AgentStatusPipe,
  CoverageLevelPipe,
  DatePipe,
  DayHourMinDiffPipe,
  FormatSecondsPipe,
  MinDiffNumPipe,
  MinDiffPipe,
  NotInsuredReasonPipe,
  QuoteIntentPipe,
  QuoteReasonPipe,
  StripDatePipe,
  VerticalNamePipe,
} from '../dashboard/quotes/quotes.pipes';

import { AdminNotesService, UrlsService } from '@services/index';
import { provideNgxWebstorage, withNgxWebstorageConfig, withLocalStorage, withSessionStorage } from 'ngx-webstorage';
import { JsonEditorWrapperComponent } from './json-editor/json-editor.component';
import { NgJsonEditorModule } from 'ang-jsoneditor';

@NgModule({
  declarations: [
    DatePipe,
    CoverageLevelPipe,
    QuoteReasonPipe,
    AddressPipe,
    VerticalNamePipe,
    LoaderComponent,
    DateTimeInputComponent,
    TextInputComponent,
    PasswordInputComponent,
    FieldErrorComponent,
    TabsMenuComponent,
    SummaryItemComponent,
    FilterToggleComponent,
    DatepickerFilterComponent,
    DatetimeQuickFilterComponent,
    MongoFilterInputComponent,
    FiltersPanelComponent,
    ModalComponent,
    ConfirmModalComponent,
    SwitcherComponent,
    SearchPanelComponent,
    DashboardPanelComponent,
    TextareaComponent,
    NoteComponent,
    AdminNotesComponent,
    DeleteEntityComponent,
    LazyLoadComponent,
    SelectComponent,
    Ms2DurationPipe,
    ConsumerContactCardComponent,
    TerminalComponent,
    FindFilterComponent,
    AgentStatusPipe,
    MinDiffPipe,
    MinDiffNumPipe,
    DayHourMinDiffPipe,
    QuoteIntentPipe,
    NotInsuredReasonPipe,
    StripDatePipe,
    FormatSecondsPipe,
    Ms2DurationPipe,
    QuoteReasonPipe,
    JsonEditorWrapperComponent,
  ],
  exports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    LoaderComponent,
    DateTimeInputComponent,
    TextInputComponent,
    PasswordInputComponent,
    FieldErrorComponent,
    TabsMenuComponent,
    SummaryItemComponent,
    FilterToggleComponent,
    DatepickerFilterComponent,
    DatetimeQuickFilterComponent,
    MongoFilterInputComponent,
    FiltersPanelComponent,
    ModalComponent,
    ConfirmModalComponent,
    SwitcherComponent,
    SearchPanelComponent,
    DashboardPanelComponent,
    TextareaComponent,
    NoteComponent,
    AdminNotesComponent,
    DeleteEntityComponent,
    LazyLoadComponent,
    SelectComponent,
    TerminalComponent,
    Ms2DurationPipe,
    AddressPipe,
    VerticalNamePipe,
    CoverageLevelPipe,
    ConsumerContactCardComponent,
    DatePipe,
    FindFilterComponent,
    Ms2DurationPipe,
    QuoteReasonPipe,
    AgentStatusPipe,
    MinDiffPipe,
    MinDiffNumPipe,
    DayHourMinDiffPipe,
    QuoteIntentPipe,
    NotInsuredReasonPipe,
    StripDatePipe,
    FormatSecondsPipe,
    JsonEditorWrapperComponent,
  ],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    AppMaterialModule,
    NgJsonEditorModule,
  ],
  providers: [
    CookieService,
    IpLookupService,
    UseragentLookupService,
    RequestService,
    EmailAsyncValidatorService,
    DaterangeService,
    CarriersService,
    ExportService,
    PromoService,
    AdminNotesService,
    MessagesService,
    UrlsService,
    provideHttpClient(withInterceptorsFromDi()),
    provideNgxWebstorage(
      withNgxWebstorageConfig({ separator: '|', caseSensitive: true }),
      withLocalStorage(),
      withSessionStorage(),
    ),
  ],
})
export class SharedModule {}
