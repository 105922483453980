export const QUOTE_STATUS = {
  QUEUED: 'queued',
  SELECTING: 'selecting',
  QUOTING: 'quoting',
  RT_QUOTING: 'rt_quoting',
  QUOTED: 'quoted',
  ACCEPTED: 'accepted',
  REJECTED: 'rejected',
  EXPIRED: 'expired',
  PAUSED: 'paused',
  BAD: 'bad',
  REVIEW: 'review',
  FAILED: 'failed',
};

export const AGENT_STATUSES: string[] = ['no_funds', 'waiting_renewal', 'premium_increased',
  'minimal_savings', 'no_answer', 'unfamiliar_carrier', 'bound', 'in_progress', 'went_elsewhere', 'other'];

export const AGENT_STATUS_SELECT: any = [
  { value: 'no_funds', slug: 'no_funds' },
  { value: 'waiting_renewal', slug: 'waiting_renewal' },
  { value: 'premium_increased', slug: 'premium_increased' },
  { value: 'minimal_savings', slug: 'minimal_savings' },
  { value: 'no_answer', slug: 'no_answer' },
  { value: 'unfamiliar_carrier', slug: 'unfamiliar_carrier' },
  { value: 'bound', slug: 'bound' },
  { value: 'in_progress', slug: 'in_progress' },
  { value: 'went_elsewhere', slug: 'went_elsewhere' },
  { value: 'other', slug: 'other' },
];

export const CONTACT_TYPE_SELECT: any = [
  { value: '{"$exists":false}', slug: 'No Contact' },
  { value: 'call_in', slug: 'Call In' },
  { value: 'text_in', slug: 'Text In' },
  { value: 'calendly', slug: 'Calendly' },
  { value: 'chat', slug: 'Chat' },
  { value: 'email', slug: 'Email' },
  { value: 'call_out', slug: 'Call Out' },
];

export const STATUS_SELECT = [
  {
    value: QUOTE_STATUS.QUEUED,
    slug: 'Queued',
  },
  {
    value: QUOTE_STATUS.SELECTING,
    slug: 'Selecting',
  },
  {
    value: QUOTE_STATUS.QUOTING,
    slug: 'Quoting',
  },
  {
    value: QUOTE_STATUS.EXPIRED,
    slug: 'Expired',
  },
  {
    value: QUOTE_STATUS.RT_QUOTING,
    slug: 'RT Quoting',
  },
  {
    value: QUOTE_STATUS.QUOTED,
    slug: 'Quoted',
  },
  {
    value: QUOTE_STATUS.REJECTED,
    slug: 'Rejected',
  },
  {
    value: QUOTE_STATUS.REVIEW,
    slug: 'Review',
  },
  {
    value: QUOTE_STATUS.ACCEPTED,
    slug: 'Accepted',
  },
  {
    value: QUOTE_STATUS.PAUSED,
    slug: 'Paused',
  },
  {
    value: QUOTE_STATUS.BAD,
    slug: 'Bad',
  },
];

export const CYCLE_SELECT = [
  {
    value: '1',
    slug: 'New',
  },
  {
    value: '{"$gt":1}',
    slug: 'Re-shop',
  },
];

export const QUOTE_REASON_SELECT = [
  {
    value: 'reshop RTWS',
    slug: 'Automatic Reshop',
  },
  {
    value: 'manual_reshop',
    slug: 'Manual Reshop',
  },
  {
    value: 'customer_reshop',
    slug: 'Customer Reshop',
  },
  {
    value: 'passive_reshop',
    slug: 'Passive Reshop',
  },
  {
    value: 'expired_refresh',
    slug: 'Expired Refresh',
  },

];

export const QUOTE_TYPES_SELECT = [
  {
    value: '{"numManualQuotes": {"$gt": 0}}',
    slug: 'Manually Quoted',
  },
  {
    value: '{"reviewStartedAt": {"$exists": true}}',
    slug: 'Reviewed',
  },
];

export const INQUIRIES_SELECT = [
  {
    value: '{"scheduled": true}',
    slug: 'Scheduled',
  },
  {
    value: '{"messaging": true}',
    slug: 'Messaging',
  },
  {
    value: '{"call": true}',
    slug: 'Call',
  },
];

export const SAVINGS_SELECT = [
  {
    value: '{"amount":{"$gt":0}}',
    slug: 'With Savings',
  },
  {
    value: '{"amount":{"$lt":0}}',
    slug: 'No Savings',
  },
];

export const QUOTES_MENU = [
  {
    name: 'details',
    slug: 'Details',
  },
  {
    name: 'realtime',
    slug: 'Realtime',
  },
  {
    name: 'manual',
    slug: 'Manual',
  },
  {
    name: 'consumer',
    slug: 'Consumer',
  },
  // {
  //   name: 'brokers',
  //   slug: 'Brokers',
  // },
  {
    name: 'communications',
    slug: 'Communications',
  },
  {
    name: 'squeezeable',
    slug: 'Squeezeable',
  },
  {
    name: 'adminNotes',
    slug: 'Notes',
  },
  {
    name: 'ezlynxQuotes',
    slug: 'EZ',
  },
];
