import { Injectable } from '@angular/core';
import { RequestService } from '@services/request.service';

@Injectable({
  providedIn: 'root',
})
export class EmailVerifyService {

  constructor(private request: RequestService) { }

  // tslint:disable-next-line:typedef
  public verify(email: string) {
    return this.request.promise('get', `/api/email/verify?email=${email}`);
  }

}
