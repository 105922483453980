<header class='header' *ngIf="showHeader">
  <div class='header-inner'>
    <div class='header-logo-wrapper'>
      <a [routerLink]="['/quotes']" class='header-logo-link'>
        <span class='logo'>
          <i class='icon-squeeze-logo-white'></i>
        </span>
      </a>
    </div>
    <nav class='nav-center' *ngIf="admin">
      <ul class='nav-list'>
        <li routerLink="/accounts" routerLinkActive="active">
          <i class='fa fa-user'></i>
          <span>Accounts</span>
        </li>
        <li routerLink="/consumers" routerLinkActive="active">
          <i class='fa fa-users'></i>
          <span>Consumers</span>
        </li>
        <li routerLink="/partners" routerLinkActive="active">
          <i class='fa fa-handshake'></i>
          <span>Partners</span>
        </li>
        <li routerLink="/quotes" routerLinkActive="active">
          <i class='fa fa-bookmark'></i>
          <span>Quotes</span>
        </li>
        <li routerLink="/reshops" routerLinkActive="active">
          <i class="fas fa-retweet"></i>
          <span>Reshops</span>
        </li>
        <li routerLink="/referrers" routerLinkActive="active">
          <i class="fas fa-user-friends"></i>
          <span>Referrers</span>
        </li>
        <li routerLink="/messages" routerLinkActive="active">
          <i class="fas fa-comment-alt"></i>
          <span>Messaging</span>
        </li>
        <li routerLink="/trackers" routerLinkActive="active">
          <i class='fa fa-chart-bar'></i>
          <span>Trackers</span>
        </li>
        <li routerLink="/urls" routerLinkActive="active">
          <i class="fas fa-link"></i>
          <span>Urls</span>
        </li>
      </ul>
    </nav>
    <div class='nav-right'>
      <div class='user-dropdown'>
        <i class='fa fa-user'></i>
        <span *ngIf="admin">{{admin.account.emailAddress}}</span>
      </div>
      <li><a class='logout-link' (click)="logout()">Logout</a> </li>
    </div>
  </div>
</header>
