import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

@Injectable()
export class FakeUserService {

  constructor(private http: HttpClient) { }

  public randomUser(): Promise<any> {
    return this.http.get('https://randomuser.me/api/').toPromise()
      .then(res => {
        const json: any = res;
        return json.results[0];
      });
  }

  public getAvatar(): string {

    const features = this.genRandomFeatures();

    return `https://api.adorable.io/avatars/face/${features.eye}/${features.nose}/${features.mouth}/${features.color}`;
  }

  private random(ary: Array<any>): any {
    return ary[Math.floor(Math.random() * ary.length)];
  }

  private genRandomFeatures(): any {

    const features = {
      face: {
        eyes: [
          'eyes1',
          'eyes10',
          'eyes2',
          'eyes3',
          'eyes4',
          'eyes5',
          'eyes6',
          'eyes7',
          'eyes9',
        ],
        nose: [
          'nose2',
          'nose3',
          'nose4',
          'nose5',
          'nose6',
          'nose7',
          'nose8',
          'nose9',
        ],
        mouth: [
          'mouth1',
          'mouth10',
          'mouth11',
          'mouth3',
          'mouth5',
          'mouth6',
          'mouth7',
          'mouth9',
        ],
      },
      colors: [
        '81ecec',
        '55efc4',
        '74b9ff',
        'a29bfe',
        'dfe6e9',
        'ffeaa7',
        'fab1a0',
        'ff7675',
        'fd79a8',
        '636e72',
        'fdcb6e',
      ],
    };

    return {
      eye: this.random(features.face.eyes),
      nose: this.random(features.face.nose),
      mouth: this.random(features.face.mouth),
      color: this.random(features.colors),
    };
  }

}
