import {
  Component,
  OnInit,
} from '@angular/core';
import {
  Router,
} from '@angular/router';
import { LocalStorage } from 'ngx-webstorage';
import { AuthService } from '@services/index';
import { environment } from '@environments/environment';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
})
export class HeaderComponent implements OnInit {

  public altBg: boolean = true;
  public showUserMenu: boolean = false;

  get admin(): any {
    return this.auth.admin;
  }

  get routeName(): string {
    return this.router.url.split('?')[0];
  }

  get showHeader(): boolean {
    return this.routeName.indexOf('auth') === -1;
  }

  get isProduction(): boolean {
    return environment.envName === 'prod';
  }

  public logout(): void {
    this.auth.logout().then((_response: any): void => {
      // this.router.navigateByUrl('/auth/signin');
      location.reload();
    });
  }

  constructor(
    public auth: AuthService,
    public router: Router,
    ) { }

  ngOnInit(): void {
  }

}
