import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { RequestService } from '@services/request.service';
import { IRequestOptions } from '@common/interfaces/request.interface';
import { IPromo } from '@common/interfaces/promo.interface';

@Injectable()
export class PromoService {

  public promoList: Array<any> = [];

  constructor(
    private request: RequestService,
  ) {}

  getAll(params: IRequestOptions = {}): Observable<any> {
    params.limit = params.limit || 100;
    return this.request.send('get', '/api/promo', params) as Observable<any>;
  }

  get(params: { code: string }): Observable<any> {
    return this.request.send('get', '/api/promo', params) as Observable<any>;
  }

  update(params: IPromo): Observable<any> {
    return this.request.send('post', '/api/promo', params) as Observable<any>;
  }

  deletePromo(params: { code: string }): Observable<any> {
    return this.request.send('post', '/api/promo/delete', params) as Observable<any>;
  }

  apply(params: { code: string, accountId: string}): Promise<any> {
    return this.request.promise('post', '/api/promo/apply', params);
  }
}
