import { IAddress, IAddress2 } from '@common/interfaces';
import { ucFirstMultipleWords } from './string.utils';

export function isCompleteAddress(address: IAddress | IAddress2): boolean {
  const addr: IAddress = AsIAddress(address);
  return (!!addr.street &&
    !!addr.unit &&
    !!addr.city &&
    !!addr.state &&
    !!addr.zipcode &&
    !!addr.country);
}

export function AsIAddress(address: IAddress | IAddress2 | any): IAddress {
  if (!!(address as IAddress2)?.address1) {
    return IAddress2ToIAddress(address);
  } else if (!!(address as IAddress)?.street) {
    return address;
  } else {
    return {} as IAddress;
  }
}

export function IAddressToIAddress2(address: IAddress | null | undefined): IAddress2 {
  return address ? {
    address1: address.street,
    address2: address.unit,
    city: address.city,
    state: address.state,
    zipcode: address.zipcode,
    country: address.country,
  } : { } as IAddress2;
}

export function IAddress2ToIAddress(address: IAddress2 | null | undefined): IAddress {
  return address ? {
    street: address.address1,
    unit: address.address2,
    city: address.city,
    state: address.state,
    zipcode: address.zipcode,
    country: address.country,
  } : { } as IAddress;
}

export function addressAsString(address: IAddress | IAddress2 | Partial<IAddress>): string {
  let str: string = '';
  if (!address) {
    return str;
  }
  const iaddress = address as IAddress;
  const iaddress2 = address as IAddress2;
  if (iaddress.street) {
    str = iaddress.street ? ucFirstMultipleWords(iaddress.street) : '';
    str += iaddress.unit ? ' ' + ucFirstMultipleWords(iaddress.unit) : '';
  } else if (iaddress2.address1) {
    str = iaddress2.address1 ? ucFirstMultipleWords(iaddress2.address1) : '';
    str += iaddress2.address2 ? ' ' + ucFirstMultipleWords(iaddress2.address2) : '';
  }
  str += iaddress.city ? (str.length > 0 ? ', ' : '') + ucFirstMultipleWords(iaddress.city) : '';
  str += iaddress.state ? ', ' + (iaddress.state.length > 2 ? ucFirstMultipleWords(address?.state || '') : address.state)  : '';
  str += iaddress.zipcode ? ' ' + ucFirstMultipleWords(iaddress.zipcode) : '';
  return str;
}

// if zip is 33314-7203 this returns 33314
export function reduceZipcode(zipcode: string): string {
  if (zipcode && zipcode.indexOf('-') > -1) {
    zipcode = zipcode.split('-')[0];
  }
  return zipcode;
}

// if country is "USA" returns "us"
export function reduceUSAbbr(abbr: string): string {
  if (abbr && abbr.toLowerCase() === 'usa') {
    return 'us';
  }
  return abbr;
}
