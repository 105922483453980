import { NgModule } from '@angular/core';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { RouterModule, Routes } from '@angular/router';
import { provideHttpClient, withInterceptorsFromDi } from '@angular/common/http';

import { SharedModule } from '@shared/shared.module';
import { httpInterceptorProviders } from './http-interceptors';

import { AppComponent } from './app.component';
import { HeaderComponent } from './layout/header/header.component';
import { MessagesComponent } from './messages/messages.component';
import { AppMaterialModule } from './material.module';
import { NgJsonEditorModule } from 'ang-jsoneditor';

import {
  AuthService as Auth,
  AuthGuardService as AuthGuard,
  MessageService,
  FakeUserService,
  GuidService,
  VersionCheckService,
} from '@services/index';
import { provideNgxWebstorage, withLocalStorage, withNgxWebstorageConfig, withSessionStorage } from 'ngx-webstorage';
import { TrackersService } from '@services/trackers.service';

const routes: Routes = [
  {
    path: '',
    loadChildren: () => import('./dashboard/dashboard.module').then(m => m.DashboardModule),
    canActivate: [AuthGuard],
  },
  {
    path: 'session-log',
    component: MessagesComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'auth',
    loadChildren: () => import('./auth/auth.module').then(m => m.AuthModule),
  },
];

@NgModule({
  declarations: [
    AppComponent,
    MessagesComponent,
    HeaderComponent,
  ],
  bootstrap: [AppComponent],
  imports: [
    BrowserAnimationsModule,
    SharedModule,
    AppMaterialModule,
    NgJsonEditorModule,
    RouterModule.forRoot(routes),
  ],
  providers: [
    Auth,
    AuthGuard,
    httpInterceptorProviders,
    MessageService,
    FakeUserService,
    GuidService,
    VersionCheckService,
    TrackersService,
    provideHttpClient(withInterceptorsFromDi()),
    provideNgxWebstorage(
      withNgxWebstorageConfig({ separator: '|', caseSensitive: true }),
      withLocalStorage(),
      withSessionStorage(),
    ),
  ],
})
export class AppModule { }
